import { template as template_a48cfda0fd99445c9d16e383615a5f63 } from "@ember/template-compiler";
const WelcomeHeader = template_a48cfda0fd99445c9d16e383615a5f63(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
